.ucd_details
  .english_prof_employed_through_english
    display: none

  .ucd-intro
    p
      font-size: $tiny-font-size

      +breakpoint(medium)
        padding-left: $g8-2x
        padding-right: $g8-5x

    img
      max-width: $g8-6x
      height: auto

  &__toggle
    margin: $g8-4x 0 $g8-2x 0

  div.english_prof_radio_buttons
    display: none

  p.no__notes
    display: none
    font-size: 13px

  .no__notes
    display: none
    font-size: 13px

  .yes__notes
    display: none
    font-size: 13px

  #unique_id_for_english_proficiency_file
    display: none

  .yes-note
    font-size: 13px

  ul.no__notes.english_prof
    li.english_prof
      list-style-type: none

  .input-toggle
    input[type='radio'] + label::after
      top: 0.56rem !important
